import { createRouter, createWebHistory } from "vue-router";
import LogIn from "@/views/login/LogIn.vue";
import ResetPassword from "@/views/login/ResetPassword.vue";
import NotFound from "@/views/redirect/404.vue";

import useAuthStore from "../stores/auth";

const routes = [
   {
      path: "/",
      name: "landing",
      beforeEnter: (to, from, next) => {
         router.push('/login')
         next();
      },
   },
   {
      path: "/login",
      name: "login",
      component: LogIn,

      beforeEnter: (to, from) => {
         if (from.name == 'login') {
            return
         }

         if (from.name != 'forgot-password') {
            localStorage.setItem('redirectFrom', from.path)
         }
      },
   },
   {
      path: "/forgot-password",
      name: "forgot-password",
      component: ResetPassword
   },
   {
      path: "/panel",
      name: "dashboard",
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/apartments",
      name: "apartments",
      component: () => import('@/views/apartments/Apartments.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/apartments/:id",
      name: "apartment-details",
      component: () => import('@/views/apartments/ApartmentDetails.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/bookings",
      name: "bookings",
      component: () => import('@/views/bookings/Bookings.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/current-bookings",
      name: "current-bookings",
      component: () => import('@/views/bookingsSimple/BookingsSimple.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/bookings/multicalendar",
      name: "multicalendar",
      component: () => import('@/views/bookings/BookingsMulticalendar.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/checkins/:code?/:date?",
      name: "checkins",
      component: () => import('@/views/checkins/CheckIn.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/cleanings/:date/:code?",
      name: "cleanings",
      component: () => import('@/views/cleanings/Cleaning.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/new-tickets",
      name: "tickets",
      component: () => import('@/views/tickets/Tickets.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/maintenance",
      name: "maintenance",
      component: () => import('@/components/maintenance/MainPage.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/chats",
      name: "chats",
      component: () => import('@/views/chats/Chats.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/communications",
      name: "communications",
      component: () => import('@/views/otas/Otas.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/financial/conciliation",
      name: "conciliation",
      component: () => import('@/views/financial/Conciliation.vue'),
      meta: { requiresAuth: true },
   },
   {
      path: "/financial/billing/menu",
      name: "billing-menu",
      component: () => import('@/views/financial/BillingMenu.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/financial/collection",
      name: "collection",
      component: () => import('@/views/financial/Collection.vue'),
      meta: { requiresAuth: true },
   },
   {
      path: "/users",
      name: "users",
      component: () => import('@/views/users/UsersMyPlazze.vue'),
      meta: { requiresAuth: true },
   },
   {
      path: "/config/services-guest",
      name: "guest-services",
      component: () => import('@/views/mp-settings/GuestBookServices.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/config/auto-messages",
      name: "auto-messages",
      component: () => import('@/views/mp-settings/AutoMessages.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/config/templates",
      name: "chat-templates",
      component: () => import('@/components/newChatTemplates/ChatTemplates.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/config/data",
      name: "my-organization",
      component: () => import('@/views/mp-settings/OrganizationSettings.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/config/areas",
      name: "groups",
      component: () => import('@/views/mp-settings/GroupsSettings.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/config/clients",
      name: "clients",
      component: () => import('@/views/mp-settings/OrganizationClients.vue'),
      meta: { requiresAuth: true }
   },

   {
      path: "/config/providers",
      name: "providers",
      component: () => import('@/views/mp-settings/OrganizationProviders.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/config/bookings-import",
      name: "bookings-import",
      component: () => import('@/views/mp-settings/OrgSync.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/config/clients/:id",
      name: "client-info",
      component: () => import('@/views/mp-settings/ClientDetails.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: "/config/clients",
      name: "organization-clients",
      component: () => import('@/views/mp-settings/OrganizationClients.vue'),
      meta: { requiresAuth: true }
   },
   {
      path: '/404',
      name: '404',
      component: NotFound
   },
   {
      path: "/:catchAll(.*)",
      redirect: to => {
         return '/404'
         // window.location.href = import.meta.env.VITE_API_URL + to.path;
      }
   }
]

const router = createRouter({
   history: createWebHistory(`/`),
   routes,
   base: '/'
});

router.beforeEach((to, from, next) => {

   const authStore = useAuthStore()

   // if (to.matched.some(record => record.meta.requiresAuth)) {
   //     if (authStore.isLoggedIn) {
   //         console.log('entra aqui router auth')
   //         next();
   //     } else {
   //         console.log('entra aqui router not auth')
   //         next("/login");
   //     }
   // } else {
   //     next();
   // }
   next();
});



export default router;
